.videoSidebar {
    position: absolute;
    top: 40%;
    right: 0px;
    color: white;
  }
  
  .videoSidebar__button {
    padding: 10px;
    text-align: center;
    cursor: pointer;
  }
  @media(max-width:500px){
    .videoSidebar{
        right: 5px;
    }
  }
