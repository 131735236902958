.video{
    position: relative;
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
 
}   
.shot_video{
    object-fit: fill;
    width: 100%;
    height: 100%;
}